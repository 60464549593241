import type { UserDataSqwonChat } from '~/types/general/user';

export const useSqwonChat = (userChatData?: UserDataSqwonChat, isReInit?: boolean) => {
  if (!userChatData && !isReInit) {
    deleteSQWONChat();

    return;
  }

  if (isReInit) {
    deleteSQWONChat();
  }

  if (userChatData) {
    sqwonChatInitialization(userChatData);
  }
};

const deleteSQWONChat = () => {
  document.getElementById('sqwonchatConfig')?.remove();
  document.querySelector('div.sqwonchat-container')?.remove();
};

const sqwonChatInitialization = (userDataForInitialization: UserDataSqwonChat) => {
  const sqwonchatConfigScriptId = 'sqwonchatConfig';
  const sqwonchatInitScriptId = 'sqwonchatInit';

  // Create and add the config script
  const configScriptElement = document.createElement('script');

  configScriptElement.id = sqwonchatConfigScriptId;
  configScriptElement.innerHTML = `window.SqwonchatConfig = ${JSON.stringify(userDataForInitialization)};`;
  document.body.appendChild(configScriptElement);

  // Create and add the chat widget script
  const chatWidgetScriptElement = document.createElement('script');

  chatWidgetScriptElement.id = sqwonchatInitScriptId;
  chatWidgetScriptElement.src = useRuntimeConfig()?.public?.sqwonChatUrl;
  document.body.appendChild(chatWidgetScriptElement);
};
